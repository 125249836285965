<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Rapport d'évaluation des restaurants",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Rapport d'évaluation des restaurants",
      data: [],
      todayNotes: null,
      monthlyRestFeedback: null,
      today: "",
      items: [
        {
          text: "Catalogue des services",
          href: "/",
        },
      ],

      totalFeedbackChart: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
    };
  },

  methods: {
    _updateTotalFeedbackChart(monthlyRestFeedbackChart) {
      this.totalFeedbackChart.series = monthlyRestFeedbackChart.series;
      this.totalFeedbackChart.chartOptions = {
        labels: monthlyRestFeedbackChart.labels,
      };
    },
    getDailyReport() {
      var loader = this.$loading.show();
      this.$http
        .post("/feedback/restFeedbackReport")
        .then((res) => {
          loader.hide();
          res = res.data;
          this.today = res.today;
          this.todayNotes = res.dailyRestFeedback;
          this.monthlyRestFeedback = res.monthlyRestFeedback;

          this._updateTotalFeedbackChart(res.monthlyRestFeedbackChart);
        })
        .catch(() => {
          loader.hide();
          var errorNotif = new Audio(this.errorSoundurl);
          errorNotif.play();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },
  },

  mounted() {
    this.getDailyReport();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <button class="btn btn-primary" @click="getDailyReport">
               <i class="fas fa-undo-alt"></i> Actualiser
            </button> <hr>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="card-title">
                      Evaluation Journalière ({{ today }})
                    </h4>
                    <p class="card-title-desc"></p>
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <thead class="thead-light">
                          <tr>
                            <th>Restaurant</th>
                            <th>Very poor</th>
                            <th>Poor</th>
                            <th>Average</th>
                            <th>Good</th>
                            <th>Excellent</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="todayNotes">
                            <tr
                              v-for="(todayNote, index) in todayNotes"
                              v-bind:key="index"
                            >
                              <th scope="row">{{ todayNote.restName }}</th>
                              <td>{{ todayNote.veryPoor.formated }}</td>
                              <td>{{ todayNote.poor.formated }}</td>
                              <td>{{ todayNote.average.formated }}</td>
                              <td>{{ todayNote.good.formated }}</td>
                              <td>{{ todayNote.excellent.formated }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <h4 class="card-title mb-3">Aperçu</h4>
                    <div>
                      <!-- <div
                        id="overview-chart"
                        class="apex-charts"
                        dir="ltr"
                      ></div> -->
                      <apexchart
                        v-if="todayNotes"
                        class="apex-charts"
                        height="340"
                        type="pie"
                        dir="rtl"
                        :series="totalFeedbackChart.series"
                        :options="totalFeedbackChart.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <div class="col-md-6">
                <h4 class="card-title">Evaluation Mensuelle</h4>
                <p class="card-title-desc"></p>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th>Date</th>
                        <th>Very poor</th>
                        <th>Poor</th>
                        <th>Average</th>
                        <th>Good</th>
                        <th>Excellent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="monthlyRestFeedback">
                        <tr
                          v-for="(monthlyRestFeedbackRec,
                          index) in monthlyRestFeedback"
                          v-bind:key="index"
                        >
                          <th scope="row">{{ index }}</th>
                          <td>
                            {{ monthlyRestFeedbackRec.score.veryPoor.formated }}
                          </td>
                          <td>
                            {{ monthlyRestFeedbackRec.score.poor.formated }}
                          </td>
                          <td>
                            {{ monthlyRestFeedbackRec.score.average.formated }}
                          </td>
                          <td>
                            {{ monthlyRestFeedbackRec.score.good.formated }}
                          </td>
                          <td>
                            {{
                              monthlyRestFeedbackRec.score.excellent.formated
                            }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
